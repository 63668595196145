#image_video{
    border-radius: 5px;
    border: white 1px solid;
    width: 70px;
    margin-right: 10px;
    margin-bottom: 5px;
}
.vkuiPanelHeader__content-in {
    font-size: 18px !important;
}
.ql-container {
/*//100vh*/
    font-size: 16px;
    height: auto;
    border: none !important;
    /*max-height: 250px;*/
    overflow: auto;
    min-height: 200px;
}
.vkuiSimpleCell__children {
    overflow-wrap: break-word !important;
    /*overflow-wrap: break-word;!important;*/
}
.video-player {
    /*border-radius: 10px;*/
    overflow: hidden;
    border: #dbdada solid 1px;
    /*z-index: 1;*/
    /*height: 320px;*/
    /*width: 480px;*/
}
.navtext-panel{
    /*width: 30px!important;*/
}
.vkuiChipsInputBase {
    padding: 0!important;
}
.btn-time {
    width: 30px !important;
}
div.inline { float:left; }

#div-main {
    display: flex;
    /*border: 1px solid black;*/
    height: 100vh;
    flex-flow: column;
}
#div-instruction {
    padding: 20px;
    line-height: 1.5em;
    white-space:pre-wrap;
}

#list-notes {

    max-height:600px;
 /*overflow: scroll;*/
    overflow-y: scroll;
}
#link-text-note {
    padding: 12px;
    overflow: hidden;
    font-size: small;
}
#div-notes {
    overflow: scroll;
    font-size: 30px;
    /*background-color: red;*/
}
#div-nav-time {
    padding: 8px;
    border-bottom: #c8c8c8 solid 1px;
}
#div-video {
    /*height: 350px !important;*/
    /*flex: 1;*/
    /*display: flex;*/
    /*flex-direction: column;*/
    /*border: 1px solid red;*/
    /*background-color: green;*/
}
#title-video{
    font-size: 16px!important;
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
}
